import { Home } from "Pages";
import { MainLayout } from "Components/Layouts";
import { getInstantDeals, getReferralBonus, getBlogs } from "Services";
import { HomeProps } from "Types";
import {
  METADATA_TITLE,
  METADATA_DESCRIPTION,
  REVALIDATE_TIME,
} from "Constants/Common.Constant";

const HomePage = (props: HomeProps) => {
  return (
    <MainLayout
      title={METADATA_TITLE.home}
      description={METADATA_DESCRIPTION.home}
    >
      <Home {...props} />
    </MainLayout>
  );
};

export async function getServerSideProps() {
  const [instantDealsRes, referralBonusRes, blogs] = await Promise.all([
    getInstantDeals({ random: true }),
    getReferralBonus(),
    getBlogs(3),
  ]);

  return {
    props: {
      instantDeals: instantDealsRes.records,
      referralBonus: referralBonusRes.amount,
      blogs,
    },
  };
}

export default HomePage;
